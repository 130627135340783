import montserrat from "src/helpers/fonts/montserrat.web";

const fontFamilies = {
	regular: montserrat.style.fontFamily,
	bold: montserrat.style.fontFamily,
	italic: montserrat.style.fontFamily,
	boldItalic: montserrat.style.fontFamily,
	semibold: montserrat.style.fontFamily,
	semiboldItalic: montserrat.style.fontFamily,
};
export default fontFamilies;
