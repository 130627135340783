import { TextStyle } from "react-native";
import { color } from "src/styles";

export const xlFont = {
	fontSize: 31,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const largeFont = {
	fontSize: 25,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const semiLargeFont = {
	fontSize: 21,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const basicFont = {
	fontSize: 17,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const smallFont = {
	fontSize: 15,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const xsFont = {
	fontSize: 13,
	color: color.Text["Dark"],
} satisfies TextStyle;

export const spacedFont = {
	fontSize: smallFont.fontSize,
	letterSpacing: 4.404,
} satisfies TextStyle;

export const largeLetterSpacing = 4;
