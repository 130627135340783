import localFont from "next/font/local";

export const montserrat = localFont({
	src: [
		{
			path: "../../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf",
		},
		{
			path: "../../assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf",
			style: "italic",
		},
	],
});

export default montserrat;
