import useDeviceStore from "src/stores/useDeviceStore";
import { UseDevice } from "./types";

const useDevice: UseDevice = () => {
	const device = useDeviceStore((state) => state.device);

	return {
		device,
		native: false,
	};
};
export default useDevice;
