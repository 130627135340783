import { create } from "zustand";
import { MOBILE_CUTOFF } from "./constants";

const useDeviceStore = create<{
	device: "mobile" | "desktop";
}>(() =>
	typeof window === "undefined"
		? {
				device: "mobile",
			}
		: {
				device: window.innerWidth < MOBILE_CUTOFF ? "mobile" : "desktop",
			},
);
if (typeof window !== "undefined") {
	window.addEventListener("resize", () => {
		const newDevice = window.innerWidth < MOBILE_CUTOFF ? "mobile" : "desktop";
		const curDevice = useDeviceStore.getState().device;
		if (newDevice !== curDevice) {
			useDeviceStore.setState({ device: newDevice });
		}
	});
}
export default useDeviceStore;
